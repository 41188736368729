/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import NotFound from '../views/NotFound.vue';
import VueScrollTo from 'vue-scrollto';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'PARCEL - Documentation',
    },
  },
  {
    path: '/404', name: 'error', component: NotFound,
    meta: {
      title: 'Oups... - PARCEL',
    },
  },
  { path: '/:pathMatch(.*)*', name: 'not-found', component: NotFound, },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      VueScrollTo.scrollTo(to.hash, 700);
      return { selector: to.hash }
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title;

  next();

}
);

export default router;
