<template>
  <div
    class="
      lg:px-24 lg:py-24
      md:py-20 md:px-44
      px-4
      py-24
      items-center
      flex
      justify-center
      flex-col-reverse
      lg:flex-row
      md:gap-28
      gap-16
    "
  >
    <div class="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
      <div class="relative">
        <div class="absolute">
          <div class="">
            <h1 class="my-2 text-gray-800 font-bold text-2xl">
              Cette page n'existe pas...
            </h1>
            <p class="my-2 text-gray-800">
              Vous pouvrez revenir à la page principale de la documentation.
            </p>
            <button
              class="
                sm:w-full
                lg:w-auto
                my-2
                border
                rounded-xl
                md
                py-3
                px-8
                text-center
                bg-green-600
                text-white
                hover:bg-green-700
                focus:outline-none
                focus:ring-2
                focus:ring-indigo-700
                focus:ring-opacity-50
              "
              @click="goHome"
            >
              D'accord !
            </button>
          </div>
        </div>
        <div>
          <img src="https://i.ibb.co/G9DC8S0/404-2.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';

const router = useRouter();
const goHome = () => {
  router.push({ path: '/' });
};
</script>
