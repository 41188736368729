<template>
  <div
    class="
      flex flex-col flex-grow
      border-r border-gray-200
      pt-5
      pb-4
      bg-white
      overflow-y-auto
    "
  >
    <div class="flex items-center flex-shrink-0 px-4">
      <a @click.prevent="goHome()" @keypress="goHome()" style="cursor: pointer">
        <img
          class="h-20 w-auto"
          src="../assets/img/parcel_logo.svg"
          alt="Parcel Documentation"
      /></a>
    </div>
    <div class="mt-5 flex-grow flex flex-col">
      <nav class="flex-1 px-2 space-y-1 bg-white" aria-label="Sidebar">
        <template v-for="item in links" :key="item.name">
          <div v-if="!item.endpoints">
            <a
              :class="[
                item.current
                  ? 'bg-gray-100 text-gray-900'
                  : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                'group w-full flex items-center pl-7 pr-2 py-2 text-sm font-medium rounded-md',
              ]"
            >
              {{ item.title }}
            </a>
          </div>
          <Disclosure as="div" v-else class="space-y-1" v-slot="{ open }">
            <DisclosureButton
              :class="[
                item.current
                  ? 'bg-gray-100 text-gray-900'
                  : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                'group w-full flex items-center pr-2 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500',
              ]"
            >
              <svg
                :class="[
                  open ? 'text-gray-400 rotate-90' : 'text-gray-300',
                  'mr-2 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150',
                ]"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
              </svg>
              {{ item.title }}
            </DisclosureButton>
            <DisclosurePanel class="space-y-1">
              <DisclosureButton
                v-for="subItem in item.endpoints"
                :key="subItem.label"
                as="a"
                :class="currentItem.value == subItem.label ? 'font-bold' : ''"
                class="
                  group
                  w-full
                  flex
                  items-center
                  pl-10
                  pr-2
                  py-2
                  text-sm
                  font-medium
                  text-gray-600
                  rounded-md
                  hover:text-gray-900 hover:bg-gray-50
                "
                @click="setAnchor(subItem)"
                @keypress="setAnchor(subItem)"
              >
                {{ subItem.label }}
              </DisclosureButton>
            </DisclosurePanel>
          </Disclosure>
        </template>
        <div class="absolute inset-x-0 bottom-0 h-16 text-center footer">
          <span
            ><a href="https://parcel-app.org/mentions" target="_blank"
              >Mentions légales</a
            ></span
          >
        </div>
      </nav>
    </div>
  </div>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { getCurrentInstance } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const router = useRouter();

const goHome = () => {
  router.push({ path: '/' });
  window.scrollTo(0, 0);
};

const props = defineProps({
  links: Array,
});

const currentItem = [];

const emit = defineEmits(['setAnchor']);

const setAnchor = (subItem) => {
  emit('setAnchor', subItem);
  router.push({ path: '/', component: HomeView, hash: `#${subItem.slug}` });
  currentItem.value = subItem.slug;
};

</script>

<style scoped>
.footer {
  font-size: 0.8rem;
  color: #015a5c;
}
.footer:hover {
  color: #91c422;
}
</style>
