<template>
  <!-- notif -->
  <div
    aria-live="assertive"
    class="
      fixed
      inset-0
      flex
      items-end
      px-4
      py-6
      pointer-events-none
      sm:p-6 sm:items-start
    "
    style="z-index: 2000"
  >
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="displayclipboardnotif"
          class="
            max-w-sm
            w-full
            bg-white
            shadow-lg
            rounded-lg
            pointer-events-auto
            ring-1 ring-black ring-opacity-5
            overflow-hidden
          "
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <CheckCircleIcon
                  class="h-6 w-6 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">Copié !</p>
                <p class="mt-1 text-sm text-gray-500">
                  L'URL du endpoint est dans votre presse-papier
                </p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button
                  type="button"
                  @click="displayclipboardnotif = false"
                  class="
                    bg-white
                    rounded-md
                    inline-flex
                    text-gray-400
                    hover:text-gray-500
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-indigo-500
                  "
                >
                  <span class="sr-only">Fermer</span>
                  <XIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
  <!-- notif ends -->
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        class="relative z-40 md:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>

        <div class="fixed inset-0 flex z-40">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel
              class="
                relative
                flex-1 flex flex-col
                max-w-xs
                w-full
                pt-5
                pb-4
                bg-white
              "
            >
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    class="
                      ml-1
                      flex
                      items-center
                      justify-center
                      h-10
                      w-10
                      rounded-full
                      focus:outline-none
                      focus:ring-2
                      focus:ring-inset
                      focus:ring-white
                    "
                    @click="sidebarOpen = false"
                  >
                    <span class="sr-only">Fermer</span>
                    <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <div class="flex-shrink-0 flex items-center px-4">
                <img
                  class="h-20 w-auto"
                  src="../assets/img/parcel_logo.svg"
                  alt="Parcel Documentation"
                />
              </div>
              <div class="mt-5 flex-1 h-0 overflow-y-auto">
                <nav class="px-2 space-y-1">
                  <a
                    v-for="item in chapters"
                    :key="item.id"
                    :class="[
                      item.current
                        ? 'bg-gray-100 text-gray-900'
                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                      'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                    ]"
                    @click="setAnchor(item)"
                    @keypress="setAnchor(item)"
                  >
                    {{ item.title }}
                  </a>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
          <div class="flex-shrink-0 w-14" aria-hidden="true">
            <!-- Dummy element to force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <SideBar v-model:links="chapters" @setAnchor="displayAnchor" />
    </div>

    <div class="md:pl-64 flex flex-col flex-1">
      <div class="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white">
        <button
          type="button"
          class="
            px-4
            text-gray-500
            focus:outline-none
            focus:ring-2
            focus:ring-inset
            focus:ring-indigo-500
            md:hidden
          "
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
        </button>
        <div class="flex-1 px-4 flex justify-between">
          <div class="flex-1 flex"></div>
          <div class="ml-4 flex items-center md:ml-6">
            <!-- Profile dropdown -->
            <Menu as="div" class="ml-3 relative">
              <div></div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="
                    origin-top-right
                    absolute
                    right-0
                    mt-2
                    w-48
                    rounded-md
                    shadow-lg
                    py-1
                    bg-white
                    ring-1 ring-black ring-opacity-5
                    focus:outline-none
                  "
                >
                  <MenuItem
                    v-for="item in userNavigation"
                    :key="item.name"
                    v-slot="{ active }"
                  >
                    <a
                      :href="item.href"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]"
                      >{{ item.name }}</a
                    >
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>

      <main class="flex-1">
        <div class="py-6">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mb-6">
            <h1 class="text-2xl font-semibold text-gray-900">
              Bienvenue dans la documentation
              <span
                ><a href="https://parcel-app.org" target="_blank"
                  >de l'application Parcel</a
                ></span
              >
            </h1>
          </div>
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <!-- Main content -->
            <!-- intro -->
            <div class="bg-gray-200 overflow-hidden rounded-lg">
              <div class="px-4 py-5 sm:p-6">
                <div v-html="intro" class="intro"></div>
              </div>
            </div>
            <!-- chapters  -->
            <div
              class="bg-gray-100 overflow-hidden rounded-lg mt-10"
              v-for="chapter in chapters"
              :key="chapter.id"
            >
              <div class="px-4 py-5 sm:p-6">
                <h2 class="text-lg leading-6 font-medium text-gray-900">
                  {{ chapter.number }} - {{ chapter.title }}
                </h2>
                <!-- endpoints -->
                <div
                  v-for="endpoint in chapter.endpoints"
                  :key="endpoint.id"
                  :id="endpoint.slug"
                  class="
                    bg-white
                    overflow-hidden
                    shadow
                    rounded-lg
                    divide-y divide-gray-200
                    mt-6
                    mb-6
                  "
                >
                  <div class="px-4 py-5 sm:px-6">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                      {{ endpoint.label }}
                    </h3>
                  </div>
                  <div class="px-4 py-5 sm:p-6">
                    <!-- Content goes here -->
                    <div
                      v-for="webservice in endpoint.webservices"
                      :key="webservice.index"
                    >
                      <div
                        class="
                          rounded-md
                          bg-gray-50
                          px-6
                          py-5
                          sm:flex sm:items-start sm:justify-between
                          mb-6
                        "
                      >
                        <div class="sm:flex sm:items-start">
                          <div class="mt-3 sm:mt-0 sm:ml-4">
                            <!-- <div class="text-sm font-medium text-gray-900">
                              Endpoint
                            </div> -->
                            <div
                              class="
                                mt-1
                                text-sm text-gray-600
                                sm:flex sm:items-center
                              "
                            >
                              <code>{{ webservice.code }}</code>
                            </div>
                          </div>
                        </div>
                        <div class="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
                          <button
                            type="button"
                            class="
                              inline-flex
                              items-center
                              px-4
                              py-2
                              border border-gray-300
                              shadow-sm
                              font-medium
                              rounded-md
                              text-gray-700
                              bg-white
                              hover:bg-gray-50
                              focus:outline-none
                              focus:ring-2
                              focus:ring-offset-2
                              focus:ring-indigo-500
                              sm:text-sm
                            "
                            @click="urltoClipboard(webservice.code)"
                          >
                            Copier
                          </button>
                        </div>
                      </div>
                      <p v-html="webservice.comment" class="comment"></p>
                    </div>
                  </div>
                  <div class="px-4 py-4 sm:px-6" v-if="endpoint.details">
                    <!-- Content goes here -->
                    <div v-html="endpoint.details"></div>
                    <!-- We use less vertical padding on card footers at all sizes than on headers or body sections -->
                  </div>
                </div>
                <!-- end endpoints -->
              </div>
            </div>
            <!-- end chapters -->
            <!-- end content -->
          </div>
        </div>
        <a
          @click.prevent="loadAnchor()"
          @keypress="loadAnchor"
          style="display: none"
          >Ancre</a
        >
      </main>
    </div>
  </div>
</template>

<script setup>
import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  BellIcon,
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuAlt2Icon,
  UsersIcon,
  CheckCircleIcon,
} from '@heroicons/vue/outline';
import { SearchIcon, XIcon } from '@heroicons/vue/solid';

import {
  onMounted, ref, watch,
} from 'vue';
import axios from 'axios';
import SideBar from '@/components/SideBar.vue';
import { useRouter, useRoute } from 'vue-router';
import VueScrollTo from 'vue-scrollto';

const router = useRouter();

const displayclipboardnotif = ref(false);

const sidebarOpen = ref(false);

const chapters = ref([]);

const intro = ref([]);

const urltoClipboard = (url) => {
  navigator.clipboard.writeText(url)
    .then(() => {
      displayclipboardnotif.value = true;
    })
    .catch((e) => {
      console.error(e);
    });
};

const route = useRoute();

const loadAnchor = () => {
  const anchor = route.hash;
  if (anchor) {
    VueScrollTo.scrollTo(anchor, 700);
  }
};

onMounted(() => {
  axios.get('https://bck.parcel-app.org/api/doc/?format=json')
    .then((response) => {
      chapters.value = response.data;
    })
    .catch((error) => {
      window.console.log(error);
      // this.errored = true;
    });
  axios.get('https://bck.parcel-app.org/api/doc/intro/?format=json')
    .then((response) => {
      intro.value = response.data;
    })
    .catch((error) => {
      window.console.log(error);
    });
  setTimeout(() => {
    loadAnchor();
  }, 1000);
  // this.errored = true;
  // VueScrollTo.scrollTo(anchor, 700);
});

</script>

<style scoped>
a {
  color: #015a5c;
}
code {
  word-break: break-all;
}
.comment {
  word-break: break-all;
}
</style>
